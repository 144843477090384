import { createHash } from 'crypto'
import { Webinar } from 'vidbase-types/webinar'

export const appName = 'Vidbase'
export const BRANCH = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF
export const viewerProtocol = 'https'
export const appProtocol = BRANCH ? 'https' : 'http'

interface GenerateGitBranchURLProps {
  projectName: string
  scopeSlug: string
  customDomains?: { [branch: string]: string }
  port?: number
}

const encryptSha256 = (str: string) => {
  const hash = createHash('sha256')
  hash.update(str)
  return hash.digest('hex')
}

const generateGitBranchURL = ({
  projectName,
  scopeSlug,
  customDomains = {},
  port,
}: GenerateGitBranchURLProps) => {
  if (!BRANCH) {
    return `localhost:${port}`
  }

  if (customDomains[BRANCH]) {
    return customDomains[BRANCH]
  }

  const branchName = BRANCH.replace('/', '-').replace(/_/g, '')
  let previewURL = `${projectName}-git-${branchName}-${scopeSlug}`
  const MAX_LENGTH_OF_URL = 63
  const MAX_LENGTH_OF_HASH = 6
  const DASHES_BETWEEN_HASH = 2
  const MAX_LENGTH_OF_URL_WITHOUT_HASH =
    MAX_LENGTH_OF_URL -
    MAX_LENGTH_OF_HASH -
    DASHES_BETWEEN_HASH -
    scopeSlug.length
  if (previewURL.length > MAX_LENGTH_OF_URL) {
    const newUrl = `${projectName}-git-${branchName}`.substring(
      0,
      MAX_LENGTH_OF_URL_WITHOUT_HASH
    )
    const hash = encryptSha256('git-' + BRANCH + projectName).slice(
      0,
      MAX_LENGTH_OF_HASH
    )
    previewURL = `${newUrl}-${hash}-${scopeSlug}`
  }
  previewURL += '.vercel.app'
  return previewURL
}

export const viewerURL = generateGitBranchURL({
  projectName: 'vidbase-viewer',
  scopeSlug: 'betamind',
  customDomains: {
    main: 'viewer.staging.vidbase.jp',
    release: 'viewer.vidbase.jp',
  },
  port: 3002,
})

export const appURL = generateGitBranchURL({
  projectName: 'vidbase-app',
  scopeSlug: 'betamind',
  customDomains: {
    main: 'app.staging.vidbase.jp',
    release: 'app.vidbase.jp',
  },
  port: 3000,
})

export const adminURL = generateGitBranchURL({
  projectName: 'vidbase-admin',
  scopeSlug: 'betamind',
  customDomains: {
    main: 'admin.staging.vidbase.jp',
    release: 'admin.vidbase.jp',
  },
  port: 3001,
})

export const mainScriptSrc =
  viewerProtocol + '://' + viewerURL + process.env.NEXT_PUBLIC_MAIN_SCRIPT_SRC
export const embedScriptPath = process.env.NEXT_PUBLIC_EMBED_SCRIPT_PATH
export const channelPluginKey = process.env.NEXT_PUBLIC_CHANNEL_PLUGIN_KEY || ''
export const amplitudeApiKey = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || ''

export const verifyEmailcontinueURL = `${appProtocol}://${appURL}/settings/?mfa_setting_open=true`

type MA = 'pardot' | 'marketo'

const defaultCustomUserId = '{{Recipient.Email}}'

export const getQueryIdentifier = (ma?: MA) => {
  if (ma === 'marketo') {
    return {
      key: 'marketoLeadId',
      value: '{{lead.id}}',
    }
  } else if (ma === 'pardot') {
    return {
      key: 'sfid',
      value: '{{Recipient.ID}}',
    }
  } else {
    return {
      key: 'custuid',
      value: defaultCustomUserId,
    }
  }
}

export const getWebinarSurveyUrl = (
  webinar: Webinar,
  params?: Record<string, string>
) => {
  switch (webinar.surveyType) {
    case 'url':
      return webinar.surveyUrl || null
    case 'vidbase': {
      const urlObj = new URL(
        `${viewerProtocol}://${viewerURL}/webinars/${webinar.id}/survey`
      )
      urlObj.search = new URLSearchParams(params).toString()
      return urlObj.toString()
    }
    default:
      return null
  }
}
