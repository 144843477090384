// TODO: move to "@common"
import { KeyboardEvent } from 'react'

export const isCmdOrCtrl = (e: KeyboardEvent) => e.metaKey || e.ctrlKey

export const isEnter = (e: KeyboardEvent) => e.key === 'Enter'

export const isCmdOrCtrlEnter = (e: KeyboardEvent) =>
  isCmdOrCtrl(e) && isEnter(e)

export const isBrowser = typeof window !== 'undefined'

export const getReferrer = (): string | null => {
  return document?.referrer || null
}

export const isCookieEnabled =
  typeof navigator !== 'undefined' && navigator.cookieEnabled

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
      Partial<Record<Exclude<Keys, K>, undefined>>
  }[Keys]

export const exitFullscreen = () => {
  if (document['exitFullscreen']) {
    document['exitFullscreen']()
  } else if ((document as any)?.['mozCancelFullScreen']) {
    ;(document as any)?.['mozCancelFullScreen']()
  } else if ((document as any)?.['webkitExitFullscreen']) {
    ;(document as any)?.['webkitExitFullscreen']()
  } else if ((document as any)?.['msExitFullscreen']) {
    ;(document as any)?.['msExitFullscreen']()
  }
}

export const enterFullscreen = (element: HTMLElement) => {
  if (element['requestFullscreen']) {
    element['requestFullscreen']()
  } else if ((element as any)?.['mozRequestFullScreen']) {
    ;(element as any)?.['mozRequestFullScreen']()
  } else if ((element as any)?.['webkitRequestFullscreen']) {
    ;(element as any)?.['webkitRequestFullscreen']()
  } else if ((element as any)?.['msRequestFullscreen']) {
    ;(element as any)?.['msRequestFullscreen']()
  }
}
