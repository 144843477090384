import type { User } from 'firebase/auth'
import { atom } from 'recoil'
import { RecoilAtomKeys } from '@/store/keys'

type AuthUserState = {
  authUser: User | undefined
  loading: boolean
  error: Error | undefined
}

export const authUserState = atom<AuthUserState>({
  key: RecoilAtomKeys.AUTH_USER_STATE,
  default: {
    authUser: undefined,
    loading: true,
    error: undefined,
  },
  // https://scrapbox.io/study-react/Recoil_Hooks_%E3%81%8C_TypeError:_Cannot_freeze_%E3%82%A8%E3%83%A9%E3%83%BC%E3%82%92%E6%8A%95%E3%81%92%E3%82%8B
  dangerouslyAllowMutability: true,
})
