import type { User as AuthUser } from 'firebase/auth'
import { amplitudeClient } from '@/services/amplitude'

type Event = 'page_view' | 'click_play_video' | 'click_action_button'

export const logEvent = (
  event: Event,
  eventParams?: {
    [key: string]: any
  }
) => {
  if (!amplitudeClient) return
  amplitudeClient.logEvent(event, eventParams)
}

export const setUserProperties = (authUser: AuthUser) => {
  if (!amplitudeClient) return
  amplitudeClient.setUserProperties({
    user_id: authUser.uid,
    anonymous: authUser.isAnonymous,
  })
}
