import { getAnalytics, Analytics } from 'firebase/analytics'
import { initializeApp, FirebaseApp } from 'firebase/app'
import { getAuth, connectAuthEmulator, Auth } from 'firebase/auth'
import {
  getFirestore,
  connectFirestoreEmulator,
  Firestore,
  initializeFirestore,
} from 'firebase/firestore'
import {
  getFunctions,
  connectFunctionsEmulator,
  Functions,
} from 'firebase/functions'
import {
  getStorage,
  connectStorageEmulator,
  FirebaseStorage,
} from 'firebase/storage'
import { firebaseConfig } from 'vidbase-utils/firebase/config'
import { emulatorFlag } from 'vidbase-utils/vidbase/flags'
import { isBrowser } from '@/utils'

let app: FirebaseApp
let auth: Auth | undefined
let analytics: Analytics | undefined
let db: Firestore
let storage: FirebaseStorage
let functions: Functions

if (isBrowser) {
  app = initializeApp(firebaseConfig)
  analytics = getAnalytics()
  initializeFirestore(app, {
    ignoreUndefinedProperties: true,
  })
  db = getFirestore()
  storage = getStorage()
  functions = getFunctions(app, 'asia-northeast1')

  if (emulatorFlag) {
    analytics = undefined
    connectFirestoreEmulator(db, 'localhost', 8080)
    connectStorageEmulator(storage, 'localhost', 9199)
    connectFunctionsEmulator(functions, 'localhost', 5001)
  }
}

const DB_EMULATOR_STARTED = 'DB_EMULATOR_STARTED'
let serverSideDB: Firestore
const getServerSideDB = (): Firestore => {
  if (serverSideDB) return serverSideDB

  app = initializeApp(firebaseConfig)

  serverSideDB = getFirestore()

  if (
    emulatorFlag &&
    !isBrowser &&
    // @ts-ignore
    !global[DB_EMULATOR_STARTED]
  ) {
    // @ts-ignore
    global[DB_EMULATOR_STARTED] = true

    connectFirestoreEmulator(serverSideDB, 'localhost', 8080)
  }
  return serverSideDB
}

// NOTE: Firebase Auth is not work well on incognito mode of browsers, So we fire "getAuth" in a function.
// "getAuth" use LocalStorage internally, and incognito mode blocks the operation which is 3rd party reads data.
const connectAuth = () => {
  auth = getAuth()
  if (emulatorFlag) {
    connectAuthEmulator(auth, 'http://localhost:9099', {
      disableWarnings: true,
    })
  }
}

export {
  app,
  auth,
  analytics,
  db,
  storage,
  functions,
  getServerSideDB,
  connectAuth,
}
