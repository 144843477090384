import amplitude, { AmplitudeClient } from 'amplitude-js'
import { amplitudeApiKey } from 'vidbase-utils/vidbase/domain'

let amplitudeClient: AmplitudeClient | undefined

if (typeof window !== 'undefined' && amplitudeApiKey) {
  amplitude.getInstance().init(amplitudeApiKey)

  amplitudeClient = amplitude.getInstance()
}

export { amplitudeClient }
