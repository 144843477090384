import { User as AuthUser } from 'firebase/auth'
import { useRecoilValue } from 'recoil'
import { authUserState } from '@/store/authUser'

const useAuthUser = (): [AuthUser | undefined, boolean, Error | undefined] => {
  const { authUser, loading, error } = useRecoilValue(authUserState)
  return [authUser, loading, error]
}

export default useAuthUser
