import React, { useEffect } from 'react'
import useAuthUser from '@/hooks/auth/useAuthUser'
import { setUserProperties } from '@/services/amplitude/analytics'

interface AnalyticsProps {
  children?: React.ReactNode
}

const Analytics = ({ children }: AnalyticsProps) => {
  const [authUser] = useAuthUser()

  useEffect(() => {
    if (authUser) {
      setUserProperties(authUser)
    }
  }, [authUser])

  return <>{children}</>
}

export default Analytics
