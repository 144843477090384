import { DefaultSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { RecoilRoot } from 'recoil'
import 'vidbase-ui/styles.css'
import Analytics from '@/components/shared/analytics'
import Auth from '@/components/shared/auth'
import { logEvent } from '@/services/amplitude/analytics'
import { connectAuth } from '@/services/firebase/client'
import { isBrowser, isCookieEnabled } from '@/utils'

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  const [isFirstRendering, setIsFirstRendering] = useState(false)

  const handleRouteChange = (url: string) => {
    logEvent('page_view', { page_path: url })
  }

  // NOTE: support for the first rendering
  if (isBrowser && !isFirstRendering) {
    handleRouteChange(router.asPath)
    setIsFirstRendering(true)
  }

  // NOTE: first rendering and client rendering
  if (isCookieEnabled && isBrowser && !isFirstRendering) {
    connectAuth()
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <RecoilRoot>
      <DefaultSeo
        defaultTitle="ポータル"
        noindex={true}
        nofollow={true}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicon.ico',
            keyOverride: 'favicon',
          },
        ]}
      />
      <Auth>
        <Analytics>
          <Component {...pageProps} />
        </Analytics>
      </Auth>
    </RecoilRoot>
  )
}

export default App
