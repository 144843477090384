import { signInAnonymously } from 'firebase/auth'
import React, { useEffect } from 'react'
import useSyncAuthUser from '@/hooks/auth/useSyncAuthUser'
import { auth } from '@/services/firebase/client'

interface AuthProps {
  children?: React.ReactNode
}

const Auth = ({ children }: AuthProps) => {
  useSyncAuthUser()
  useEffect(() => {
    if (!auth) return
    signInAnonymously(auth).catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
      console.error(
        'failed to authenticate anonymously',
        errorCode,
        errorMessage
      )
    })
  }, [])

  return <>{children}</>
}

export default Auth
