// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs'
import { Event } from '@sentry/nextjs'
import { CaptureConsole } from '@sentry/integrations'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const STAGE = process.env.NEXT_PUBLIC_BUILD_ENV || 'dev'

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://12f05fc86e634048adedccd35efa5cf3@o1052952.ingest.sentry.io/6067828',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  environment: STAGE,
  ignoreErrors: ['Non-Error exception captured'],
  integrations: [
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
  beforeSend(event: Event) {
    if (STAGE === 'dev') return null
    return event
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
